import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import {Link, navigate} from 'gatsby'

import BackgroundIndex from '../images/index-background.png';
import BackgroundTornYAxis from '../images/torn-y-axis.png';
import AboutImage from '../images/about-index.png';
import IndexSmall from '../images/index-small.png';
import QrIndex from '../images/qr-index.png';
import QrBlack from '../images/qr-black.png';
import AppMockup from '../images/app-mockup.png'; 

import Women from '../images/2women.png'; 
import MultiHandLarge from '../images/multi-hand-large.png'; 
import AboutMultiImage from '../images/about-multi-image.png';
// SVG
import DotList from '../images/svg/dot-list.svg';
import VectorAbout from '../images/svg/vector-about.svg';



const Index = ({ data }) => {
    console.log("Index data : ", data);
    return (
        <main>
        <div className="bg-gray-900 lg:overflow-hidden relative" style={{  
            backgroundImage: "url(" + BackgroundIndex + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            }}>
          <div className="mx-auto">
            <div className="md:grid md:grid-cols-2">
              <div className="mx-auto sm:max-w-2xl md:flex md:items-center md:px-0 md:text-left">
                <div className="md:py-24 md:pl-20">
                  <h1 className="text-comorant lg:text-7xl text-6xl tracking-tight text-white mb-6 pt-44 md:pt-0 md:px-0 px-14 uppercase">
                    <span className="block"><span className='italic'>LEARN </span><span className="font-semibold">MORE</span></span>
                    <span className="block font-semibold">ABOUT HOW WE</span>
                    <span className="block"><span className="font-semibold">GO TO </span><span className='italic'>WHERE </span><span className="font-semibold">WE</span></span>
                    <span className="block italic">WE ARE TODAY</span>
                  </h1>
                  <div className='block md:hidden w-full'>
                    <img className='w-full' src={IndexSmall} alt="Index Image" />
                  </div>
                </div>
              </div>
              <div className="lg:relative m-0 hidden md:block">
                <div className="mx-auto max-w-md sm:max-w-2xl lg:max-w-none lg:px-0">
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                  <img className='float-right' src={AboutImage} alt="Index Image" />
                </div>
              </div>
            </div>
          </div>
        </div>

      
        {/* DISCOVER EMOTION SECTION */}

        <div className="bg-light py-24 sm:py-32 lg:py-40 pt-72" style={{  
            backgroundImage: "url(" + BackgroundTornYAxis + ")",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="mx-auto max-w-7xl px-6 lg:px-8 mb-36	">
            <div className="mb-b">
              <h2 className="text-comorant lg:text-8xl text-5xl tracking-tight text-black mb-6 text-left">
                ABOUT US
              </h2>
            </div>
            <div className="lg:grid lg:grid grid-cols-3">
              <div className="lg:block hidden">
                <img className='mx-auto' src={VectorAbout} alt="" />
              </div>
              <div className="col-span-2 text-left">
                <p>Our journey started way back in 2015 when Kenneth and Anita started the website Lifestyle Fever. It was a hobby project that grew to over 5,000 users thanks to events and regular involvement in the community.
                  In 2022, we rebranded to Club Mella to bring a softer touch to the community. (Mella means “honey” in Latin.) We surveyed our users to understand the features they’re looking for and put an emphasis on discretion and privacy, which were top-voted by our female users.
                  In 2023, our iOS and Android apps launched and we held a private party for our beta test users to get their feedback. New enhancements are on the way and we look forward to connecting with you on the app or in our discussion groups!</p>
              </div>
            </div>

          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            {/*  OUR VISION SECTION */}
            <div className='uppercase text-5xl text-comorant'>
              <span className="block"><span className='font-semibold'>Our vision is a </span><span className="italic">world where sex</span></span>
              <span className="block ml-24"><span className="italic">positivity helps </span><span className='font-semibold'>more people</span></span>
              <span className="block"><span className="italic">discover </span><span className='font-semibold'>their bliss.</span></span>
            </div>
            <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
              <img src={AppMockup} alt="" />
            </div>
          </div>
        </div>

        



        {/* MISSION SECTION */}

        <div className="bg-light py-24 sm:py-32 lg:py-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="lg:grid lg:grid grid-cols-2 flex flex-col-reverse">
              
              <div className="">
                <div className="mx-auto">
                  <img className='lg:max-w-sm' src={MultiHandLarge} alt="Hand touching" />
                </div>
              </div>
              <div className="text-left">
                <h3 className='text-5xl	text-comorant mb-5'>Mission</h3>
                <p className='text-base	mb-10'>To create an environment of discovery for like-minded couples and individuals to explore new ideas about sexuality and pleasure.</p>
                <div class="btn-join">Join now</div>
              </div>

            </div>
          </div>
        </div>


        {/*  OUR VALUE */}

        <div className="bg-bown-400">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mb-14">
              <h2 className="text-comorant uppercase lg:text-8xl text-5xl tracking-tight text-black mb-6 text-left">
                OUR VALUES
              </h2>
            </div>
            <div className="lg:grid lg:grid grid-cols-3 flex flex-col">
              <div className="">

                <div className='mb-14 flex'>
                  <div className='w-20	pt-4'>
                    <img className='' src={DotList} />
                  </div>
                  <div>
                    <div className='text-comorant uppercase mb-5 lg:text-5xl text-2xl'>
                      Connection
                    </div>
                    <p className='text-base'>We help couples foster deeper connections built on respect, communication, trust, and satisfaction.</p>
                  </div>
                </div>
                <div className='mb-14 flex'>
                  <div className='w-20	pt-4'>
                    <img className='' src={DotList} />
                  </div>
                  <div>
                    <div className='text-comorant uppercase mb-5 lg:text-5xl text-2xl	'>
                    Playfulness
                    </div>
                    <p className='text-base'>We infuse elements of fun into our community to keep things lively and enjoyable.</p>
                  </div>
                </div>
                <div className='mb-14 flex'>
                  <div className='w-20	pt-4'>
                    <img className='' src={DotList} />
                  </div>
                  <div>
                    <div className='text-comorant uppercase mb-5 lg:text-5xl text-2xl	'>
                    Discretion
                    </div>
                    <p className='text-base'>We provide flexible privacy options for members who value discretion so they can partake on their own terms, with peace of mind.</p>
                  </div>
                </div>
                <div className='mb-14 flex'>
                  <div className='w-20	pt-4'>
                    <img className='' src={DotList} />
                  </div>
                  <div>
                    <div className='text-comorant uppercase mb-5 lg:text-5xl text-2xl	'>
                    Discovery
                    </div>
                    <p className='text-base'>We encourage the healthy exploration of new ideas and social connections that bring arousing experiences to life.</p>
                  </div>
                </div>

              </div>
              <div className="col-span-2">
                <img className='about-multi-image' src={AboutMultiImage} alt="Multi Image" />
              </div>
            </div>

          </div>
        </div>





      </main>
    )
}


export default Index